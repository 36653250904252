<template>
    <div class="profile" :style="{ backgroundImage: 'url(' + loginUser.homeBackground + ')' }">
      <div class="avatar" @click="toggleModal">
        <img :src="loginUser.userHead" alt="Avatar">
      </div>
      <div class="info">
        <h1 class="name">{{ loginUser.username }}</h1>
        <p class="bio">{{ loginUser.motto }}</p>
        <ul class="contact-list">
          <li>
            <i class="fas fa-envelope"></i>
            <a :href="'mailto:{{ loginUser.email }}'">{{ loginUser.email }}</a>
          </li>
          <li>
            <i class="fas fa-phone-alt"></i>
            {{ loginUser.birthday }}
          </li>
          <li>
            <i class="fas fa-link"></i>
            <a :href="website" target="_blank">{{ loginUser.lastLogintime }}</a>
          </li>
          <li>
            <i class="fas fa-link"></i>
            <a :href="website" target="_blank">{{ loginUser.lastLoginip }}</a>
          </li>
          <li>
            <i class="fas fa-link"></i>
            <a :href="website" target="_blank">{{ loginUser.lastLoginAddress }}</a>
          </li>
        </ul>
      </div>
      <div class="other-modules">
        <h2>其他功能模块</h2>
        <!-- 添加其他模块内容 -->
      </div>
      <div v-if="showModal" class="modal" @click="toggleModal">
        <div class="modal-content">
          <img :src="loginUser.userHead" alt="Avatar">
        </div>
      </div>
    </div>
  </template>

<script>
import { userInfo } from '@/api/user.js'

export default {
    name: 'userinfo',
    data() {
        return {
            loginUser: {},
            name: 'Your Name',
            bio: 'A brief introduction about yourself.',
            avatarUrl: 'https://img2.baidu.com/it/u=569856857,3483042356&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=1157',
            email: 'yourname@example.com',
            phone: '123-456-7890',
            website: 'https://www.example.com',
            showModal: false,
        }
    },
    mounted() {
        this.getUserInfo()
    },
    methods: {
        toggleModal() {
            this.showModal = !this.showModal
        },
        getUserInfo() {
            userInfo({}).then(res => {
                this.loginUser = res.retVal
            })
        },
    },
}
</script>

<style scoped>
.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  background-color: #f4f4f4;
  background-position: center;
  background-size: cover;
}

  .avatar {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .info {
    text-align: center;
  }

  .name {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }

  .bio {
    font-size: 16px;
    margin-bottom: 20px;
    color: #555;
  }

  .contact-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-list li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .contact-list li i {
    margin-right: 10px;
    color: #888;
  }

  .contact-list li a {
    color: #333;
    text-decoration: none;
  }

  .other-modules {
    margin-top: 40px;
  }

  h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }

  .modal-content {
    max-width: 80%;
    max-height: 80%;
  }

  .modal-content img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  </style>